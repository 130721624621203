<template>
  <div>
    <v-form
      data-vv-scope="form-bundle-registered"
      key="bundleRegisteredCreateUpdate"
    >
      <v-card class="mb-2">
        <v-card-title>
          <span class="headline" v-html="getTitle()"></span>
        </v-card-title>
        <v-card-text>
          <v-row class="pt-3">
            <v-col
              cols="12"
              md="2"
              lg="2"
              v-if="editedIndex !== -1"
              class="pt-0"
            >
              <v-list dense class="pl-0 pt-0">
                <v-list-item two-line class="pl-0 pt-0">
                  <v-list-item-content>
                    <v-list-item-title
                      >{{
                        bundleTypeObj ? bundleTypeObj.name : ""
                      }}
                      Bundle</v-list-item-title
                    >
                    <v-list-item-subtitle
                      >{{
                        activeOrderRegistered.bundle
                          ? activeOrderRegistered.bundle.name
                          : ""
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line class="pl-0">
                  <v-list-item-content>
                    <v-list-item-title>Engine</v-list-item-title>
                    <v-list-item-subtitle
                      >{{
                        activeOrderRegistered.engine
                          ? activeOrderRegistered.engine.name
                          : ""
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line v-if="isEventType" class="pl-0">
                  <v-list-item-content>
                    <v-list-item-title>Available connections</v-list-item-title>
                    <v-list-item-subtitle>{{
                      activeOrderRegistered.active_connections
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col
              cols="12"
              :offset-md="editedIndex === -1 ? 2 : 0"
              :offset-lg="editedIndex === -1 ? 2 : 0"
              md="8"
              lg="8"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                  v-if="
                    errors.has('form-bundle-registered.general_error_field')
                  "
                >
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-alert type="error" text>
                        {{
                          errors.first(
                            "form-bundle-registered.general_error_field"
                          )
                        }}
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="6" v-if="editedIndex === -1">
                  <v-autocomplete
                    v-model="activeOrderRegistered.bundle"
                    :items="bundlesList"
                    outlined
                    label="Bundle"
                    :multiple="false"
                    item-value="_key"
                    item-text="name"
                    :disabled="editedIndex === -1 ? false : true"
                    v-validate="'required'"
                    :error-messages="
                      errors.collect(`form-bundle-registered.bundle`)
                    "
                    data-vv-name="bundle"
                    data-vv-validate-on="change"
                    return-object
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" v-if="editedIndex === -1">
                  <SelectEnginesAsync
                    v-model="activeOrderRegistered.engine"
                    outlined
                    label="Engine"
                    :multiple="false"
                    item-value="_key"
                    item-text="name"
                    v-validate="'required'"
                    :error-messages="
                      errors.collect(`form-bundle-registered.engine`)
                    "
                    data-vv-name="engine"
                    data-vv-validate-on="change"
                    field="engine"
                    :menu-props="{ maxHeight: 304 }"
                    return-object
                    :disabled="editedIndex === -1 ? false : true"
                  />
                </v-col>

                <v-col cols="12" md="12" v-if="editedIndex !== -1">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="activeOrderRegistered.expiration_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="activeOrderRegistered.expiration_date"
                        label="Expiration date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="activeOrderRegistered.expiration_date"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.menu.save(activeOrderRegistered.expiration_date)
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  v-if="bundleRequireIpsWhitelist"
                >
                  <v-combobox
                    v-model="activeOrderRegistered.white_list"
                    :items="items"
                    chips
                    outlined
                    label="IP whitelist"
                    multiple
                    prepend-icon="mdi-plus-circle"
                    @click:prepend="dialogWhitelistIps = true"
                    append-icon="false"
                    readonly
                    class="py-0"
                    height="44"
                  >
                    <template
                      v-slot:selection="{ attrs, item, select, selected }"
                    >
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        close
                        @click="select"
                        @click:close="removeIP(item)"
                      >
                        <strong>{{ item }}</strong>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-col>

            <!--            <template v-if="bundleRequireConnections">-->
            <!--              <v-col cols="12" sm="12">-->
            <!--                <EventsList></EventsList>-->
            <!--              </v-col>-->
            <!--            </template>-->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="error" text :to="pathRedirect">Close</v-btn>
          <v-btn color="success" text @click.stop="save()">Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <AddSingleTextDialog
      :dialog.sync="dialogWhitelistIps"
      @save-field="addNewIp"
      title="Add IP to whitelist"
      field="IP"
      rules="required|validIp"
    ></AddSingleTextDialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import "@/validators/ip/ip-validator"
import GeneralMixin from "@/mixins/general.js"
import AddSingleTextDialog from "@/components/commonComponents/AddSingleTextDialog"
import SelectEnginesAsync from "@/components/selects/SelectEnginesAsync"
// import EventsList from "../events/OLD/EventsList"

export default {
  props: {
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
  },

  data: () => ({
    dialogWhitelistIps: false,
    items: [],
    menu: false,
    date: new Date().toISOString().substr(0, 10),
  }),

  components: {
    AddSingleTextDialog,
    SelectEnginesAsync,
    // EventsList,
  },

  mixins: [GeneralMixin],

  created: function () {
    let self = this
    self.$store.dispatch("saveLoaderAction", true)
    self.getBundlesList()
    self.$store.dispatch("setBundleTypes", { p: 1, pp: 10 }).then(() => {
      self.$store.dispatch("resetOrderRegisteredItem").then(() => {
        self.getItemData().then(() => {
          self.$store.dispatch("saveLoaderAction", false)
        })
      })
    })
  },

  beforeDestroy() {
    this.$store.dispatch("resetOrderRegisteredItem")
  },

  methods: {
    removeIP(item) {
      this.activeOrderRegistered.white_list.splice(
        this.activeOrderRegistered.white_list.indexOf(item),
        1
      )
      this.activeOrderRegistered.white_list = [
        ...this.activeOrderRegistered.white_list,
      ]
    },
    addNewIp(item) {
      this.activeOrderRegistered.white_list.push(item)
    },
    getBundlesList: function () {
      this.$store.dispatch("getBundlesList", { params: { p: 1, pp: 500 } })
    },
    getTitle() {
      return this.editedIndex === -1
        ? "Register new bundle"
        : `<span class="blue-grey--text text--lighten-4">Edit registered bundle</span> ${this.activeOrderRegistered?.bundle?.name}
          <span class="blue-grey--text text--lighten-4">for engine</span> ${this.activeOrderRegistered?.engine?.name}`
    },
    getItemData() {
      return this.editedIndex !== -1
        ? this.$store.dispatch("getOrderRegisteredItem", {
            slug: this.editedIndex,
          })
        : Promise.resolve()
    },
    createRegisteredBundle(formData) {
      let self = this
      self.$store
        .dispatch("createOrderRegistered", formData)
        .then(({ data }) => {
          let params = {
            text: `Bundle <strong>${this.activeOrderRegistered.bundle.name}</strong> for engine <strong>${this.activeOrderRegistered.engine.name}</strong> was registered successfully.`,
            show: true,
          }
          self.close().then(() => {
            self.$root.$emit("snackbar", params)
          })
        })
        .catch(({ response }) => {
          self.handleValidationErrors(response, "form-bundle-registered")
        })
    },
    editRegisteredBundle(formData) {
      let self = this
      self.$store
        .dispatch("editOrderRegistered", formData)
        .then(({ data }) => {
          let params = {
            text: `Bundle <strong>${this.activeOrderRegistered.bundle.name}</strong> for engine <strong>${this.activeOrderRegistered.engine.name}</strong> was edited successfully.`,
            show: true,
          }
          self.close().then(() => {
            self.$root.$emit("snackbar", params)
          })
        })
        .catch(({ response }) => {
          self.handleValidationErrors(response, "form-bundle-registered")
        })
    },
    save() {
      let self = this
      self.$validator.errors.clear("form-bundle-registered")
      self.$validator.validateAll("form-bundle-registered").then((result) => {
        if (result) {
          let formData = {
            data: {
              expiration_date: self.activeOrderRegistered.expiration_date,
              active_connections: self.activeOrderRegistered.active_connections,
            },
          }
          if (self.editedIndex === -1) {
            formData.data = {
              bundle_key: self.activeOrderRegistered.bundle._key,
              engine_key: self.activeOrderRegistered.engine._key,
            }
          }
          if (self.bundleRequireIpsWhitelist) {
            formData.data.white_list =
              self.activeOrderRegistered.white_list || []
          }
          if (this.editedIndex > -1) {
            formData["slug"] = self.editedIndex
            self.editRegisteredBundle(formData)
          } else {
            self.createRegisteredBundle(formData)
          }
        }
      })
    },
    close() {
      let self = this
      return new Promise((resolve, reject) => {
        this.$router
          .push({ path: self.pathRedirect })
          .then(() => {
            resolve()
          })
          .catch((err) => {})
      })
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      bundlesList: "bundlesList",
      bundleTypes: "bundleTypes",
      activeOrderRegistered: "activeOrderRegistered",
      enginesCompanyList: "getEnginesCompanyList",
      enginesCompanyListCount: "enginesCompanyListCount",
      Pallette: "get_Pallette",
      isSuperUser: "isSuperUser",
    }),
    bundleRequireIpsWhitelist() {
      return (
        this.activeOrderRegistered?.bundle?._key &&
        this.bundleTypeObj?.is_white_list
      )
    },
    // bundleRequireConnections() {
    //   return true
    //   return this.bundleTypeObj?.name === 'External Event'
    // },
    bundleTypeObj: function () {
      return this.bundleTypes.find(
        (l) => l._key === this.activeOrderRegistered?.bundle?.type_key
      )
    },
    pathRedirect: function () {
      return this.isSuperUser ? "/store/bundles/registry" : "/store/bundles"
    },
    isEventType() {
      return this.bundleTypeObj?.name === "External Event"
    },
  },
}
</script>
